import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SideNavigation from './lib/Common/SideNavigation';
import './style.css';
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {fetchUser} from "./lib/Auth/User.actions";
import {LoginContainer} from "./lib/Auth/Login.container";
import {ThemeProvider} from "styled-components";
import {GlobalStyle} from "./theme/globalStyles";
import {lightTheme} from "./theme/theme";
import {UsersContainer} from "./lib/User/Users.container";
import {UserContainer} from "./lib/User/User.container";
import {CardsContainer} from "./lib/Card/Cards.container";
import {CalendarContainer} from "./lib/Calendar/Calendar.container";
import {RoomBookingContainer} from "./lib/Room/RoomBooking.container";
import {DesksContainer} from "./lib/Desk/Desks.container";
import {DeskBookingContainer} from "./lib/Desk/DeskBooking.container";
import styled from 'styled-components';
import {RoomsListContainer} from "./lib/Room/RoomsList.container";
import {RoomBookingsContainer} from "./lib/Room/RoomBookings.container";
import {RoomContainer} from "./lib/Room/Room.container";
import { EntrancesContainer } from "./lib/Entrances/Entrances.container";

const StyledMain = styled.main`
  margin-left: ${props => props.isCollapsed ? '110px !important' : '270px'};
  transition: margin .2s;
`

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isJWTChecked: false,
      isCollapsed: false,
    }
  }

  async componentDidMount() {
    await this.props.fetchUser();
    this.setState({isJWTChecked: true});
  }

  isLogged() {
    const {user} = this.props;
    return user.isLogged;
  }

  toggleMenu = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    })
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isLogged()) {

    }
  }

  render() {
    if (!this.state.isJWTChecked) {
      return null;
    }

    const {user} = this.props;

    return (
      <ThemeProvider theme={lightTheme}>
        <GlobalStyle/>
        <div className="flexible-content">
          {this.isLogged() && (
          <SideNavigation
            isCollapsed={ this.state.isCollapsed }
            toggle={ this.toggleMenu }
            user={user.email}
          />
            )}
          {this.isLogged() && (
            <StyledMain isCollapsed={this.state.isCollapsed} className="p-4">
              <Switch>
                <Route path='/' exact component={UsersContainer}/>
                <Route path='/users' exact component={UsersContainer}/>
                <Route path='/users/:id' exact component={UserContainer}/>
                <Route path='/cards' exact component={CardsContainer}/>
                <Route path='/entrances' exact component={EntrancesContainer}/>
                <Route path='/rooms' exact component={RoomsListContainer}/>
                <Route path='/calendar' exact component={CalendarContainer}/>
                <Route path='/rooms/:id' exact component={RoomContainer}/>
                <Route path='/rooms/:id/bookings' exact component={RoomBookingsContainer}/>
                <Route path='/rooms/booking/:id' exact component={RoomBookingContainer}/>
                <Route path='/desks' exact component={DesksContainer}/>
                <Route path='/desks/booking/:id' exact component={DeskBookingContainer}/>
              </Switch>
            </StyledMain>
          )}
          {!this.isLogged() && (
            <>
              <Switch>
                <Route path='/' exact component={LoginContainer}/>
              </Switch>
            </>
          )}
        </div>
      </ ThemeProvider>
    );
  }
}

App.propTypes = {
  updateRoomsData: PropTypes.func,
  getUser: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchUser: () => dispatch(fetchUser()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
