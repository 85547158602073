import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";
import {ROOM_ACTIONS} from "../Room/Room.actions";

export const DESK_ACTIONS = {
  GET_BOOKINGS: 'GET_BOOKINGS',
  GET_BOOKING: 'GET_BOOKING',
  REMOVE_BOOKING: 'REMOVE_BOOKING',
  GET_USER_SUGGESTIONS: 'GET_USER_SUGGESTIONS',
};

export const getBookings = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/desk/bookings`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: DESK_ACTIONS.GET_BOOKINGS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getBooking = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/desk/booking/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: DESK_ACTIONS.GET_BOOKING, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const saveBooking = (id, payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    if(id) {
      await axios.put(
        `${backendHost}/booking/${id}`,
        payload,
        {
          headers: {'Authorization': `Bearer ${accessToken}`}
        });
    }else{
      await axios.post(
        `${backendHost}/booking`,
        payload,
        {
          headers: {'Authorization': `Bearer ${accessToken}`}
        });
    }
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const removeBooking = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.delete(
      `${backendHost}/booking/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: DESK_ACTIONS.REMOVE_BOOKING, payload: {id}});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getUserSuggestions = (text) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/?search=${text}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.GET_USER_SUGGESTIONS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
