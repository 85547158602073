import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCloseIcon,
  MDBCol,
  MDBDataTable,
  MDBContainer,
  MDBListGroup, MDBListGroupItem,
  MDBRow, MDBBadge, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane
} from "mdbreact";
import NotificationComponent from "../Notification/Notification.component";
import {LHButton, LHButtonInput, LHInputWrap} from "../../theme/globalStyles";
import * as Styled from './User.style';
import {Redirect} from "react-router";
import moment from "moment";
import { getTypeColor } from "../Entrances/Entrances.page";

export class UserPage extends Component {

  constructor() {
    super();
    this.state = {
      isNew: true,
      id: null,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        status: '',
      },
      cards: [],
      cardsHistory: [],
      entrancesHistory: [],
      newCard: '',
      redirectToList: false,
      activeTab: '1',
    };
  }

  async componentDidMount() {
    const {getUser, getCards, getCardsHistory, getEntrancesHistory} = this.props;
    const {id} = this.props.match.params;
    if(id !== 'new') {
      const user = await getUser(id);
      const cards = await getCards(id);
      const cardsHistory = await getCardsHistory(id);
      const entrancesHistory = await getEntrancesHistory(id);
      this.setState({id, user, cards, cardsHistory, entrancesHistory});
    }
  }

  async submitForm() {
    const {id, user, cards} = this.state;
    try {
      const {saveUser, showNotification} = this.props;
      await saveUser(id, {...user, cards});
      showNotification('Użytkownik został zapisany!');
      this.setState({redirectToList: true});
    } catch (e) {
      this.setState({error: e.message});
    }
  }

  saveCard() {
    const {newCard} = this.state;
    this.setState({
        cards: [
          ...this.state.cards,
          {
            id: String(Date.now()).trim().substring(0, 10), //temporary id for remove btn purpose
            uid: newCard
          }
        ],
        newCard: '',
      },
    );
  }

  removeCard(id) {
    if (window.confirm('Czy na pewno chcesz usunąć przypisanie tej karty?')) {
      const cards = this.state.cards.filter(card => card.id !== id);
      this.setState({cards});
    }
  }

  toggle = tab => e => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const {isNew, user, cards, newCard, redirectToList, cardsHistory, entrancesHistory} = this.state;
    const {notification} = this.props;

    const mappedEntrances = entrancesHistory.map(row => {
      return {
        ...row,
        createdAt: moment(row.createdAt).format('DD.MM.YYYY HH:mm'),
        type: <MDBBadge color={getTypeColor(row.type)}>{row.type}</MDBBadge>
      }
    })

    function groupItems(array, property) {
      return array.reduce(function(groups, item) {
        var name = item[property].slice(0, 10)
        var group = groups[name] || (groups[name] = []);
        group.push(item);
        return groups;
      }, { });
    }

    const dailyEntrances = groupItems(entrancesHistory, 'createdAt')
    const mappedDailyEntrances = []

    Object.keys(dailyEntrances).forEach((key) => {
      mappedDailyEntrances.push({
        count: dailyEntrances[key].length,
        date: moment(dailyEntrances[key][0].createdAt).format('DD.MM.YYYY')
      })
    })

    if(redirectToList) return <Redirect to='/users'/>;

    if (!user && !isNew) {
      return '';
    }

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}

        <BreadcrumSection
          title={ user.id ? user.email : 'Nowy Użytkownik' }
          subtitle={ user.id ? 'Edytuj użytkownika' : 'Dodaj  osobę' }
          icon="user"
        />
        <MDBContainer fluid >
          <MDBRow>
            <MDBCol xl="6" lg="12">
              <MDBCard>
                <MDBCardBody>
                  <div className="form-group">
                    <label>Imię</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={user.firstName}
                        onChange={e => this.setState({user: {...user, firstName: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Nazwisko</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={user.lastName}
                        onChange={e => this.setState({user: {...user, lastName: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>E-mail</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={user.email}
                        onChange={e => this.setState({user: {...user, email: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  {!user.id && (
                    <div className="form-group">
                      <label>Hasło</label>
                      <LHInputWrap>
                        <input
                          type="password"
                          className="form-control"
                          value={user.password}
                          onChange={e => this.setState({user: {...user, password: e.target.value}})}
                        />
                      </LHInputWrap>
                    </div>
                  )}
                  <div className="form-group">
                    <label>Status</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={user.status}
                              onChange={e => this.setState({user: {...user, status: e.target.value}})}>
                        <option value="PENDING">Oczekujący</option>
                        <option value="ACTIVE">Aktywny</option>
                        <option value="BLOCKED">Zablokowany</option>
                        <option value="REMOVED">Usunięty</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Rola</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={user.isAdmin}
                              onChange={e => this.setState({user: {...user, isAdmin: e.target.value}})}>
                        <option value={true}>Admin</option>
                        <option value={false}>Użytkownik</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Dostępny czas rezerwacji sal</label>
                    <LHInputWrap>
                      <input
                        type="number"
                        className="form-control"
                        value={user.availableRoomsHours}
                        onChange={e => this.setState({user: {...user, availableRoomsHours: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Stripe Customer ID</label>
                    <LHInputWrap>
                      <input
                        type="number"
                        className="form-control"
                        value={user.stripeCustomerId}
                        onChange={e => this.setState({user: {...user, stripeCustomerId: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Nowa karta:</label>
                    <LHInputWrap>
                      <LHButtonInput>
                        <input
                          type="text"
                          className="form-control"
                          value={newCard}
                          placeholder="wpisz kod karty..."
                          onChange={e => this.setState({newCard: e.target.value})}
                        />
                        <LHButton className="btn btn-primary float-right" onClick={() => this.saveCard()}>
                          Dodaj kartę
                        </LHButton>
                      </LHButtonInput>
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Przypisane karty:</label>
                    <Styled.CardsList>
                      {cards.map(card =>
                        <MDBListGroupItem key={`card-${card.id}`}
                                          className="d-flex justify-content-between align-items-center">
                          {card.uid} <MDBCloseIcon onClick={() => this.removeCard(card.id)}/>
                        </MDBListGroupItem>
                      )}
                    </Styled.CardsList>
                  </div>
                  <LHButton className="btn btn-outline-purple" onClick={() => this.submitForm()}>
                    Zapisz
                  </LHButton>
                </MDBCardBody>
              </MDBCard>

            </MDBCol>
            <MDBCol xl="6" lg="12">
              <MDBCard>
                <MDBCardBody>
                  <p>
                    Historia otwierania drzwi
                  </p>
                  <MDBNav className="nav-tabs">
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeTab === "1"} onClick={this.toggle("1")} role="tab" >
                        Dzienna
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeTab === "2"} onClick={this.toggle("2")} role="tab" >
                        Cała historia
                      </MDBNavLink>
                    </MDBNavItem>
                  </MDBNav>

                  <MDBTabContent activeItem={this.state.activeTab} >
                    <MDBTabPane tabId="1" role="tabpanel">
                      <div style={{"backgroundColor": "#f1f2f8", "padding": "10px" }}>
                        {mappedDailyEntrances.length > 0 &&
                          <div className="logs-history-wrap">
                            <MDBDataTable
                              paging={true}
                              data={{
                                columns: [
                                  {label: 'Data', field: 'date', sort: 'desc'},
                                  {label: 'Ilość', field: 'count'}
                                ],
                                rows: mappedDailyEntrances}}
                            />
                          </div>
                        }
                        {mappedDailyEntrances.length == 0 &&
                          <p>Brak historii</p>
                        }
                      </div>
                    </MDBTabPane>


                    <MDBTabPane tabId="2" role="tabpanel">
                      <div style={{"backgroundColor": "#f1f2f8", "padding": "10px" }}>
                        {mappedEntrances.length > 0 &&
                          <div className="logs-history-wrap">
                            <MDBDataTable
                              paging={true}
                              data={{
                                columns: [
                                  {label: 'Data', field: 'createdAt', sort: 'desc'},
                                  {label: 'Rodzaj', field: 'type'}
                                ],
                                rows: mappedEntrances}}
                            />
                          </div>
                        }
                        {mappedEntrances.length == 0 &&
                          <p>Brak historii</p>
                        }
                      </div>
                    </MDBTabPane>
                  </MDBTabContent>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

        </MDBContainer>
      </>
    )
  }
}

UserPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

