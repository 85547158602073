import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const ROOM_ACTIONS = {
  GET_BOOKINGS: 'GET_BOOKINGS',
  GET_BOOKING: 'GET_BOOKING',
  REMOVE_BOOKING: 'REMOVE_BOOKING',
  GET_USER_SUGGESTIONS: 'GET_USER_SUGGESTIONS',
  GET_ROOMS: 'GET_ROOMS',
  GET_ROOM: 'GET_ROOM',
};

export const getRooms = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/room`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.GET_ROOMS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getRoom = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/room/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.GET_ROOM, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const saveRoom = (id, payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    if(id){
    await axios.put(
      `${backendHost}/room/${id}`,
      payload,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    }else{
      await axios.post(
      `${backendHost}/room`,
      payload,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    }
  } catch (error) {
    requestErrorHandler(error);
    throw error.response.data;
  }
};

export const getBookings = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/room/${id}/bookings`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.GET_BOOKINGS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getBooking = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/room/booking/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.GET_BOOKING, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const saveBooking = (id, payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    if(id){
    await axios.put(
      `${backendHost}/booking/${id}`,
      payload,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    }else{
      await axios.post(
      `${backendHost}/booking`,
      payload,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    }
  } catch (error) {
    requestErrorHandler(error);
    throw error.response.data;
  }
};

export const removeBooking = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.delete(
      `${backendHost}/booking/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.REMOVE_BOOKING, payload: {id}});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getUserSuggestions = (text) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/?search=${text}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ROOM_ACTIONS.GET_USER_SUGGESTIONS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
