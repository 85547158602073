import { MDBIcon, MDBLink, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import React from "react";
import {LHTableActions, LHTableStatus, LHTableWrap} from "../../../theme/globalStyles";
import * as moment from 'moment-business-days';


const getDuration = (start, stop) => {
  return moment(moment(stop).endOf('day')).businessDiff(moment(start));
};

const getLength = (start) => {
  return moment().diff(moment(start), 'days');
};

export const BookingsTableComponent = ({bookings, onBookingRemove}) => {
  return (
    <LHTableWrap>
      <MDBTable>
        <MDBTableHead>
          <tr>
            <th className="id-column-header">#</th>
            <th>Biurko</th>
            <th>Ilość</th>
            <th>Początek</th>
            <th>Koniec</th>
            <th>Czas</th>
            <th>Rezerwujący</th>
            <th>Status</th>
            <th className="text-right">Akcje</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {bookings.map(booking =>
            <tr key={`booking-id-${booking.id}`}>
              <td className="id-column-value">{booking.id}</td>
              <td>{booking.itemId ? booking.itemId : 'Hot-Desk'}</td>
              <td>{booking.quantity}</td>
              <td>{moment(booking.bookingStart).format('DD.MM.YYYY')}</td>
              <td>{!booking.itemId ? moment(booking.bookingEnd).format('DD.MM.YYYY') : '-'}</td>
              <td>{!booking.itemId ? getDuration(booking.bookingStart, booking.bookingEnd) : getLength(booking.bookingStart)} dni</td>
              <td>{booking.user.email}</td>
              <td>
                <LHTableStatus status={booking.status}>{booking.status}</LHTableStatus>
            </td>
              <td >
                <LHTableActions>
                  <MDBLink to={`/desks/booking/${booking.id}`}>
                    <MDBIcon far icon="edit" />
                  </MDBLink>
                  <div onClick={() => onBookingRemove(booking.id)}>
                    <MDBIcon far icon="trash-alt" />
                  </div>
                </LHTableActions>
              </td>
            </tr>
          )}
        </MDBTableBody>
      </MDBTable>
    </LHTableWrap>
  );
};
