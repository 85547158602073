import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const USERS_ACTIONS = {
  SET_USERS: 'SET_USERS',
  SET_USER: 'SET_USER',
  SET_DELETED_USERS: 'SET_DELETED_USERS',
  SET_CARDS: 'SET_CARDS',
  REMOVE_USER: 'REMOVE_USER',
};

export const getUsers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: USERS_ACTIONS.SET_USERS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getDeletedUsers = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/deleted`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: USERS_ACTIONS.SET_DELETED_USERS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getUser = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: USERS_ACTIONS.SET_USER, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const saveUser = (id, payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;

    if(id) {
      await axios.put(
        `${backendHost}/user/${id}`,
        payload,
        {
          headers: {'Authorization': `Bearer ${accessToken}`}
        });
    }else{
      await axios.post(
        `${backendHost}/user`,
        payload,
        {
          headers: {'Authorization': `Bearer ${accessToken}`}
        });
    }
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const removeUser = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.delete(
      `${backendHost}/user/${id}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: USERS_ACTIONS.REMOVE_USER, payload: { id }});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const removeUserCard = (id, payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.put(
      `${backendHost}/user/${id}/removeCard`,
      {
        cardId: payload
      },
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const reviveUser = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.put(
      `${backendHost}/user/${id}/revive`,
      {},
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: USERS_ACTIONS.REMOVE_USER, payload: { id }});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getCards = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/${id}/cards`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: USERS_ACTIONS.SET_CARDS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getCardsHistory = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/${id}/cards-history`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};

export const getEntrancesHistory = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/${id}/entrances`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
