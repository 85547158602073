import React, {Component} from 'react';
import {
  MDBAlert,
  MDBInput,
} from "mdbreact";
import axios from "axios";
import { LHButton} from "../../theme/globalStyles";
import styled from "styled-components";
import LoginPanel from "../Common/LoginPanel";
import {backendHost} from "../../common/helpers/http";


const StyledLoginForm = styled.form`
  h1 {
    text-align: center;
    margin-bottom: 100px;
    font-weight: bold;
    font-size: 2.6em;
  }
  input {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    background-color: #F1F2F8 !important;
  }
  label {
    left: 20px;
  }
  button {
    width: 100%;
    margin: 0;
  }
`

export class LoginPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      username: null,
      password: null,
      badCredentials: false,
      notActivated: false,
    }
    this.handleFormSubmit= this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.submitForm();
  }

  async submitForm(){
    const { username, password } = this.state;

    this.setState({ badCredentials: false, notActivated: false });
    try{
      const response = await axios.post(`${backendHost}/user/login`, {
        username, password
      });
      window.localStorage.setItem('accessToken', response.data.access_token);
      window.location.href = '/';
    }catch(error){
      const { status } = error.response;
      if(status === 401) {
        this.setState({ badCredentials: true });
      }else if(status === 503){
        this.setState({ notActivated: true });
      }
    }
  }

  render() {

    const {badCredentials, notActivated} = this.state;

    return (
        <LoginPanel>
          <StyledLoginForm onSubmit={this.handleFormSubmit}>
            <h1>Panel administracyjny</h1>

            {badCredentials &&
            <MDBAlert color="danger">
              Podano niewłaściwe dane logowania.
            </MDBAlert>
            }

            {notActivated &&
            <MDBAlert color="danger">
              Twoje konto nie zostało aktywowane. Prosimy sprawdzić pocztę e-mail w celu uzyskania
              linku aktywacyjnego.
            </MDBAlert>
            }

            <div className="grey-text">
              <MDBInput
                  label="Adres e-mail"
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                  onKeyPress={this.keyPressed}
                  onChange={(e) => this.setState({ username: e.currentTarget.value })}
              />
              <MDBInput
                  label="Hasło"
                  group
                  type="password"
                  onKeyPress={this.keyPressed}
                  onChange={(e) => this.setState({ password: e.currentTarget.value })}
                  validate
              />
            </div>
            <LHButton type="submit">Zaloguj się</LHButton>
          </StyledLoginForm>
        </LoginPanel>
    )
  }
}
