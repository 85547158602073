import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import { MDBBadge, MDBDataTable, MDBLink, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import {LHTableStatus, LHTableWrap} from "../../theme/globalStyles";

export const getTypeColor = (type) => {
  switch (type) {
    case 'GLOBAL_QR':
      return 'warning'
    case 'QR_CODE':
      return 'secondary'
    case 'BUTTON':
      return 'primary'
    case 'TABLET':
      return 'info'
    default:
      return 'danger'
  }
}

export class EntrancesPage extends Component {

  componentDidMount() {
    const {getAllEntrancesHistory, getUsers} = this.props;
    getUsers();
    getAllEntrancesHistory();
  }



  render() {
    const { entrances, users } = this.props;

    const getUserEmailById = (id) => {
      const user = users.find(user => user.id === id);
      return user ? user.email : '-';
    }

    const mappedEntrances = entrances.map(row => {
      return {
        ...row,
        createdAt: moment(row.createdAt).format('DD.MM.YYYY HH:mm'),
        type: <MDBBadge color={getTypeColor(row.type)}>{row.type}</MDBBadge>,
        user: (
          getUserEmailById(row.userId) !== '-' ?
            <MDBLink to={`/users/${row.userId}`} className="d-inline-block p-0">
              <strong>{getUserEmailById(row.userId)}</strong>
            </MDBLink> : '-'
        )

      }
    })

    return (
      <>
        <BreadcrumSection
          title="Historia otwierania drzwi"
          subtitle="Lista wszystkich użyć drzwi"
          icon="id-card"
        />

        <LHTableWrap>
          <MDBDataTable
            noBottomColumns={true}
            entries={50}
            paging={true}
            data={{
              columns: [
                {label: 'Użytkownik', field: 'user'},
                {label: 'Rodzaj', field: 'type'},
                {label: 'Data', field: 'createdAt', sort: 'desc'},
              ],
              rows: mappedEntrances}}
          />
        </LHTableWrap>
      </>
    )
  }
}

EntrancesPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};
