import {MDBIcon, MDBLink, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import moment from "moment";
import React from "react";
import {LHTableActions, LHTableStatus, LHTableWrap} from "../../../theme/globalStyles";

const getDuration = (start, stop) => {
  const duration = moment.duration(moment(stop).diff(start));
  return duration.asHours();
};

const getRoomName = (id) => {
  switch (id) {
    case 1:
      return 'Sala wideokonferencyjna - mała';
    case 2:
      return 'Sala wideokonferencyjna - duża';
    case 3:
      return 'Sala szkoleniowa';
    default:
      return 'Nieznana sala';
  }
};

const isPastBooking = (bookingEnd) => {
  if(moment(bookingEnd) < moment()) return true;
  return false;
}

export const BookingsTableComponent = ({bookings, onBookingRemove}) => {
  return (
    <LHTableWrap>
      <MDBTable>
        <MDBTableHead>
          <tr>
            <th className="id-column-header">#</th>
            <th>Sala</th>
            <th>Data</th>
            <th>Godziny</th>
            <th>Czas</th>
            <th>Rezerwujący</th>
            <th>Status</th>
            <th className="text-right">Akcja</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {bookings.map(booking =>
            <tr key={`booking-id-${booking.id}`} style={isPastBooking(booking.bookingEnd) ? {background: '#ddd'} : null}>
              <td className="id-column-value">{booking.id}</td>
              <td>{getRoomName(booking.itemId)}</td>
              <td>
                {moment(booking.bookingStart).format('DD.MM.YYYY')}
              </td>
              <td>
                <strong>
                  {moment(booking.bookingStart).format('HH:mm')}
                </strong>
                <span style={{padding:'0 5px'}}>-</span>
                <strong>
                  {moment(booking.bookingEnd).format('HH:mm')}
                </strong>
              </td>
              <td>{getDuration(booking.bookingStart, booking.bookingEnd)} godz.</td>
              <td>{booking.user.email}</td>
              <td>
                <LHTableStatus status={booking.status}>{booking.status}</LHTableStatus>
              </td>
              <td>
                <LHTableActions>
                  <MDBLink to={`/rooms/booking/${booking.id}`} className="d-inline-block p-0">
                    <MDBIcon far icon="edit" />
                  </MDBLink>
                  <div onClick={() => onBookingRemove(booking.id)}>
                    <MDBIcon far icon="trash-alt" />
                  </div>
                </LHTableActions>
              </td>
            </tr>
          )}
        </MDBTableBody>
      </MDBTable>
    </LHTableWrap>
  );
};
