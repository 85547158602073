import {USER_ACTIONS} from "./User.actions";

export const UserReducer = (state = {
  isLogged: false,
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  accessToken: null,
}, action) => {
  switch (action.type) {
    case USER_ACTIONS.UPDATE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case USER_ACTIONS.UPDATE_USER_DATA:
      return {
        ...state,
        isLogged: action.payload.isLogged,
        id: action.payload.id,
        email: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      };
    case USER_ACTIONS.LOGOUT:
      return {
        ...state,
        isLogged: false,
        id: null,
        email: null,
        firstName: null,
        lastName: null,
      };
    default:
      return state
  }
}