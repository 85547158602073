import axios from "axios";
import {backendHost} from "../../common/helpers/http";

export const USER_ACTIONS = {
  UPDATE_ACCESS_TOKEN: 'UPDATE_ACCESS_TOKEN',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  LOGOUT: 'LOGOUT',
};

export const logoutCurrentUser = () => {
  window.localStorage.removeItem('accessToken');
  window.location.href = '/';
};

export const fetchUser = () => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  if(!accessToken){
    return false;
  }

  const data = await checkIfAccessTokenIsValid(accessToken);
  if(data){
    dispatch({ type: USER_ACTIONS.UPDATE_ACCESS_TOKEN, payload: accessToken });
    dispatch({ type: USER_ACTIONS.UPDATE_USER_DATA, payload: {...data, isLogged: true } });
    return true;
  }

  window.localStorage.removeItem('accessToken');
  return false
};

const checkIfAccessTokenIsValid = async (accessToken) => {
  try {
    const response = await axios.get(`${backendHost}/user/profile`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    return response.data;
  }catch (error) {
   return false;
  }
};
