import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import {MDBIcon} from "mdbreact";

const StyledPageHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`
const StyledPageHeader = styled.div`
  display: flex;
  line-height: 1.5em;
  margin-bottom: 15px;
  align-items: center;
  padding: 10px 0;
`
const StyledPageHeaderTitle = styled.div`
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 4px;
`
const StyledPageHeaderSubtitle = styled.div`
  font-size: .9em;
  opacity: .6;
`
const StyledPageHeaderIcon = styled.div`
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  background-color: var(--main);
  color: #fff;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 24px;
  i {
    margin-right: 0 !important;
  }
`

const BreadcrumSection = ({title, subtitle, icon, children}) => {
  return (
    <StyledPageHeaderWrap>
      <StyledPageHeader>
        <StyledPageHeaderIcon>
          <MDBIcon far icon={icon} className="mr-3"/>
        </StyledPageHeaderIcon>
        <div>
          <StyledPageHeaderTitle>
            {title}
          </StyledPageHeaderTitle>
          <StyledPageHeaderSubtitle>
            {subtitle}
          </StyledPageHeaderSubtitle>
        </div>
      </StyledPageHeader>
      {children}
    </StyledPageHeaderWrap>
  )
}

BreadcrumSection.propTypes = {
  page: PropTypes.string,
};

export default BreadcrumSection;

