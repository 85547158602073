import React, { Component } from "react";
import { MDBNotification } from "mdbreact";

class NotificationComponent extends Component {
  render() {
    const {message} = this.props;
    return (
      <MDBNotification
        autohide={3000}
        bodyClassName="p-5 font-weight-bold white-text"
        className="stylish-color-dark"
        closeClassName="blue-grey-text"
        fade
        icon="bell"
        iconClassName="blue-grey-text"
        message={message}
        show
        text="teraz"
        title="Bootstrap"
        titleClassName="elegant-color-dark white-text"
        style={{position: "fixed", top: "10px", right: "10px", zIndex: 9999}}
      />
    );
  }
}
export default NotificationComponent;
