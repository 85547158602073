import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {MDBIcon, MDBLink} from "mdbreact";
import NotificationComponent from "../Notification/Notification.component";
import {BookingsTableComponent} from "./components/BookingsTable.component";
import {LHButtonOutline} from "../../theme/globalStyles";

export class RoomBookingsPage extends Component {

  constructor() {
    super();
    this.state = {
      bookings: [],
    };

    this.onBookingRemove = this.onBookingRemove.bind(this);
  }

  async componentDidMount() {
    const {id} = this.props.match.params;
    const {getBookings} = this.props;
    await getBookings(id);
  }

  async onBookingRemove(id){
    if(!window.confirm('Czy na pewno chcesz usunąć tą rezerwację?')) return false;
    const {removeBooking} = this.props;
    await removeBooking(id);
  }

  render() {
    const {bookings, notification} = this.props;

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title="Rezerwacje  sal"
          subtitle="Lista wszystkich rezerwacji sal"
          icon="building"
        >
          <MDBLink to={`/rooms/booking/new`} className="d-inline-block p-0">
            <LHButtonOutline>
              <MDBIcon icon="plus" />
              Dodaj rezerwację
            </LHButtonOutline>
          </MDBLink>
        </BreadcrumSection>

        <BookingsTableComponent bookings={bookings} onBookingRemove={this.onBookingRemove}/>
      </>
    )
  }
}

RoomBookingsPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

