import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const ENTRANCES_ACTIONS = {
  SET_ENTRANCES: 'SET_ENTRANCES',
};

export const getAllEntrancesHistory = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/user/entrances`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: ENTRANCES_ACTIONS.SET_ENTRANCES, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
