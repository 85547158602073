import React, { Component } from 'react';
import {MDBListGroup, MDBListGroupItem, MDBIcon, MDBLink} from 'mdbreact';
import {NavLink} from 'react-router-dom';
import Logout from "./Logout";
import * as Styled from './SideNavigation.style';

class SideNavigation extends Component {
  render() {
    return (
      <Styled.NavWrap collapsed={this.props.isCollapsed}>
        <div className="sidebar-fixed position-fixed">
          <div>
          <MDBLink to="/" className="logo-wrapper waves-effect">
            <Styled.NavLogo/>
          </MDBLink>
          <MDBListGroup className="list-group-flush">
            <NavLink to="/" exact activeClassName="active">
              <MDBListGroupItem>
                <MDBIcon far icon="user" className="mr-3"/>
                <span>Użytkownicy</span>
              </MDBListGroupItem>
            </NavLink>
            <NavLink to="/cards" activeClassName="active">
              <MDBListGroupItem>
                <MDBIcon far icon="id-card" className="mr-3"/>
                <span>Karty dostępu</span>
              </MDBListGroupItem>
            </NavLink>
            <NavLink to="/desks" activeClassName="active">
              <MDBListGroupItem>
                <MDBIcon far icon="square" className="mr-3"/>
                <span>Rezerwacje biurek</span>
              </MDBListGroupItem>
            </NavLink>
            <NavLink to="/rooms" activeClassName="active">
              <MDBListGroupItem>
                <MDBIcon far icon="building" className="mr-3"/>
                <span>Sale</span>
              </MDBListGroupItem>
            </NavLink>
            <NavLink to="/calendar" activeClassName="active">
              <MDBListGroupItem>
                <MDBIcon far icon="calendar" className="mr-3"/>
                <span>Kalendarz</span>
              </MDBListGroupItem>
            </NavLink>
            <NavLink to="/entrances" activeClassName="active">
              <MDBListGroupItem>
                <MDBIcon far icon="id-card" className="mr-3"/>
                <span>Historia wejść</span>
              </MDBListGroupItem>
            </NavLink>
          </MDBListGroup>
          </div>
          <Styled.NavFooter>
            <Styled.NavToggle onClick={this.props.toggle} />
            <Logout collapsed={this.props.isCollapsed} user={this.props.user}/>
          </Styled.NavFooter>
        </div>
      </Styled.NavWrap>
    );
  }
}

export default SideNavigation;
