import {connect} from "react-redux";
import {getRooms} from "./Room.actions";
import {showNotification} from "../Notification/Notification.actions";
import {RoomsListPage} from "./RoomsList.page";

const mapStateToProps = (state) => ({
  rooms: state.rooms.list,
  notification: state.notification.message,
});

const mapDispatchToProps = dispatch => {
  return {
    getRooms: (id) => dispatch(getRooms(id)),
    showNotification: (text) => dispatch(showNotification(text)),
  };
}

export const RoomsListContainer = connect(mapStateToProps, mapDispatchToProps)(RoomsListPage);
