import styled, { createGlobalStyle, css } from "styled-components";
import {MDBBtn} from "mdbreact";
import searchIcon from "../assets/icons/search.svg";

export const GlobalStyle = createGlobalStyle`
    :root {
      --main: #877BED;
      --text: #373169;
    }
    body {
      background-color: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.text};
      transition: all 0.25s linear;
    }

    .card {
      box-shadow: none;
      background-color: ${({ theme }) => theme.background};
    }
`

export const LHButton = styled(MDBBtn)`
  background-color: var(--main) !important;
  border: 2px solid var(--main) !important;
  color: #fff !important;
  border-radius: 50px;
  &:not([disabled]):not(.disabled):active {
    background-color: var(--main) !important;
  }
`
export const LHButtonOutline = styled(MDBBtn)`
  background-color: transparent !important;
  border: 2px solid var(--main) !important;
  color: var(--main) !important;
  border-radius: 50px;
  i {
    margin-right: 10px;
  }
  &:not([disabled]):not(.disabled):active {
    background-color: transparent !important;
  }
`
export const LHInputWrap = styled.div`
  input, select {
    border-radius: 50px !important;
    padding: 12px 0 12px 20px !important;
    width: calc(100% - 20px);
    height: auto !important;
    border: none; 
    border-bottom: 1px solid #ced4da !important;
    border-bottom: 1px solid ${props => props.validation ? '#F6423C !important' : ''};  
    background-color: ${props => props.validation ? 'rgba(246, 66, 60, .2) !important' : '#f1f2f8 !important'};
    &:focus {
      outline: none;
    }
  }

  .react-autosuggest__suggestions-container--open {
    background-color: #fff;
    width: calc(100% - 20px);
    margin-top: 10px;
    position: absolute;
    z-index: 10;
    border-radius: 4px;
    padding: 5px 10px;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.1);
    max-height: 400px;
    overflow-y: scroll;
    ul {
      padding: 0;
      margin-bottom: 0;
    }
    li {
      list-style: none;
      padding: 0;
    }
  }
  ${props => props.icon && css`
    position: relative;   
    input, select {
      padding-left: 45px !important;
    }
  `}
`;
export const LHButtonInput = styled.div`
  position: relative;
  button {
    position: absolute;
    right: 15px;
    top: -7px;
  }
`
export const LHInputIcon = styled.div`
  display: inline-block;
  height: 18px;
  width: 18px;
  position: absolute;
  left: 16px;
  top: 15px;
  background-size: contain;
  opacity: .5;
  ${props => props.search && css`
    background-image: url(${searchIcon});
  `}
`
export const LHAutosuggestOption = styled.div`
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 4px;
  transition: .2s;
  display: flex;
  &:hover {
    background-color: #F1F2F8; 
  }
  div {
    opacity: .5;
  }
  span {
    padding-right: 15px;
  }
`
export const LHTableWrap = styled.div`
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  padding: 20px;
  table {
    margin-bottom: 0;
  }
  thead {
    box-shadow: 0 2px 6px 0px rgba(0,0,0,0.1);
    th {
      text-transform: uppercase;
      font-weight: bold;
      font-size: .8em;
      color: #222731;
      opacity: .7;
      border-bottom: none;
      padding: 15px 20px;
    }
  }
  td {
    border-top: none;
    border-bottom: 1px solid #dee2e6;
    padding: 0 20px;
  }
  tr:last-of-type{
    td {
      border-bottom: none;
    }
  }
  .id-column-header {
    text-align: center;
    width: 20px;
  }
  .id-column-value {
    text-align: center;
    width: 20px;
    font-size: 14px;
    opacity: .6;
  }
  [data-test="datatable-pagination"] {
    padding-top: 20px;
  }
  [data-test="datatable-info"] {
    padding-top: 20px;
  }
`
export const LHTableStatus = styled.span`
  display: inline-block;
  position: relative;
  background-color: #EEF0F5;
  color: rgba(0,0,0,0.5);
  padding: 2px 12px; 
  white-space: nowrap;
  border-radius: 40px;
  font-size: .8em;
  font-weight: 600;
    ${props => props.status === 'ACTIVE' && css`
      background-color: rgba(49,200,150,.15);
      color: rgba(49,200,150,.8);
      &:before {
        background-color: #31C896;
      }
    `}
    ${props => props.status === 'BLOCKED' && css`
      background-color: rgba(255,53,71,0.15);
      color: rgba(255,53,71, .8);
      &:before {
        background-color: #ff3547;
      }
    `}
    ${props => props.status === 'PENDING' && css`
      background-color: rgba(253,163,111,.15);
      color: rgba(253,163,111,.8);
      &:before {
        background-color: rgb(253,163,111);
      }
    `}
    ${props => props.status === 'ADMIN' && css`
      background-color: rgba(255,53,71,0.15);
      color: rgba(255,53,71, .8);
      padding-left: 5px;
      &:before {
        display: none;
      }
    `}
    ${props => props.status === 'USER' && css`
      background-color: rgba(253,163,111,.15);
      color: rgba(253,163,111,.8);
      padding-left: 5px;
      &:before {
        display: none
      }
    `}
  &:before {
    content: '';
    display: inline-block;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    position: relative;
    left: -5px;
    top: -2px;
  }
`
export const LHTableActions = styled.div`
  display: flex;
  justify-content: flex-end;
 & > a, & > div {
    display: inline-block !important;
    margin-left: 20px; 
    padding: 0;
  }
`
export const LHDatePickerWrap = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    margin-right: 20px;
  }
  input {
    width: calc(100% - 20px);
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    color: rgba(0,0,0,0.65);
  }
`
