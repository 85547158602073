import {CARDS_ACTIONS} from "./Cards.actions";

export const CardsReducer = (state = {
  list: [],
}, action) => {
  switch (action.type) {
    case CARDS_ACTIONS.SET_CARDS:
      return {
        ...state,
        list: action.payload
      };
    default:
      return state;
  }
};
