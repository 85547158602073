import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow
} from "mdbreact";
import NotificationComponent from "../Notification/Notification.component";
import * as moment from 'moment-business-days';
import {LHAutosuggestOption, LHButton, LHDatePickerWrap, LHInputIcon, LHInputWrap} from "../../theme/globalStyles";
import Autosuggest from "react-autosuggest";
import {Redirect} from "react-router";
import FormDatePicker from "../Common/FormDatePicker";

export class DeskBookingPage extends Component {

  constructor() {
    super();
    this.state = {
      id: null,
      bookingStartDate: moment().toDate(),
      booking: {
        itemType: 'DESK',
        itemId: 0,
        bookingStart: moment().format('YYYY-MM-DD'),
        bookingLength: 1,
        quantity: 1,
      },
      plan: 'dailyBooking',
      userSearchQuery: '',
      redirectToList: false,
    };

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
  }

  getDuration(start, stop) {
    return moment(stop, 'YYYY-MM-DD').endOf('day').businessDiff(moment(start,'YYYY-MM-DD'));
  }

  async componentDidMount() {
    const {getBooking} = this.props;
    const {id} = this.props.match.params;
    if (id === 'new') return;
    const booking = await getBooking(id);
    this.setState({
      id,
      booking: {
        ...booking,
        bookingStart: moment(booking.bookingStart).format('YYYY-MM-DD'),
        bookingLength: this.getDuration(booking.bookingStart, booking.bookingEnd),
      },
      userSearchQuery: `${booking.user.email} - ${booking.user.firstName} ${booking.user.lastName}`,
      bookingStartDate: moment(booking.bookingStart).toDate(),
    });
  }

  async submitForm() {
    const {id, booking} = this.state;
    const {saveBooking, showNotification} = this.props;
    try {
      await saveBooking(id, booking);
      showNotification('Zmiany zostały zapisane!');
      this.setState({redirectToList: true});
    } catch (e) {
      showNotification(`Błąd: ${e.message}`);
    }
  }

  async onSuggestionsFetchRequested(event) {
    const {getUserSuggestions} = this.props;
    if(event.value.length < 2) return;
    getUserSuggestions(event.value);
  }

  updateBookingStart(date) {
    const bookingStart = moment(date).format('YYYY-MM-DD');
    const bookingStartDate = moment(date).toDate();
    this.setState({
      bookingStartDate: bookingStartDate,
      booking: {...this.state.booking, bookingStart: bookingStart},
    });
  }

  render() {
    const {booking, plan, userSearchQuery, redirectToList} = this.state;
    const {notification, userSuggestions} = this.props;

    if(redirectToList) return <Redirect to='/desks'/>;

    const renderSuggestion = suggestion => (
      <LHAutosuggestOption>
        {(suggestion.firstName || suggestion.lastName) &&
        <span>{suggestion.firstName} {suggestion.lastName}</span>
        }
        <div>{suggestion.email}</div>
      </LHAutosuggestOption>
    );

    const inputProps = {
      placeholder: 'Szukaj użytkownika',
      value: userSearchQuery,
      onChange: (event, { newValue }) => {
        this.setState({userSearchQuery: newValue});
      }
    };

    const getSuggestionValue = (suggestion) => {
      this.setState({booking: {...booking, userId: suggestion.id}});
      return `${suggestion.email} - ${suggestion.firstName} ${suggestion.lastName}`;
    };

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title={booking.id ? 'Rezerwacja #' + booking.id : "Nowa rezerwacja"}
          subtitle={booking.id  ? 'Edytuj wybraną rezerwacją' : 'Stwórz nową rezerwacją'}
          icon="square"
        />

        <MDBContainer fluid style={{background: '#FFF'}}>
          <MDBRow>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <div className="form-group">
                    <label>Użytkownik</label>
                    <LHInputWrap icon>
                      <LHInputIcon search/>
                      <Autosuggest
                        suggestions={userSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={() => {}}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        className="form-control"
                      />
                    </LHInputWrap>
                  </div>
                  {!booking.id && (
                    <div className="form-group">
                      <label>Rodzaj rezerwacji</label>
                      <LHInputWrap>
                        <select className="browser-default custom-select" value={plan}
                                onChange={e => this.setState({plan: e.target.value})}>
                          <option value="dailyBooking">Plan dzienny</option>
                          <option value="monthlyBooking">Plan miesięczny</option>
                        </select>
                      </LHInputWrap>
                    </div>
                  )}

                  {(booking.itemId || plan === 'monthlyBooking') && (
                    <div className="form-group">
                      <label>Nr biurka</label>
                      <LHInputWrap>
                        <select className="browser-default custom-select" value={booking.itemId}
                                onChange={e => this.setState({booking: {...booking, itemId: parseInt(e.target.value, 10)}})}>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                        </select>
                      </LHInputWrap>
                    </div>
                  )}
                  <div className="form-group">
                    <label>Start rezerwacji</label>
                    <FormDatePicker
                      selected={this.state.bookingStartDate}
                      onChange={date => this.updateBookingStart(date)}
                    />
                  </div>
                  {(!booking.itemId && plan === 'dailyBooking') && (
                    <>
                      <div className="form-group">
                        <label>Długość rezerwacji (dni robocze)</label>
                        <LHInputWrap>
                          <select className="browser-default custom-select"
                                  value={booking.bookingLength}
                                  onChange={e => this.setState({booking: {...booking, bookingLength: e.target.value}})}
                          >
                            <option value="1">1 dzień</option>
                            <option value="2">2 dni</option>
                            <option value="3">3 dni</option>
                            <option value="5">5 dni</option>
                            <option value="10">10 dni</option>
                            <option value="15">15 dni</option>
                          </select>
                        </LHInputWrap>
                      </div>
                      <div className="form-group">
                        <label>Ilość biurek</label>
                        <LHInputWrap>
                          <select className="browser-default custom-select"
                                  value={booking.quantity}
                                  onChange={e => this.setState({booking: {...booking, quantity: e.target.value}})}
                          >
                            <option value="1">1 biurko</option>
                            <option value="2">2 biurka</option>
                            <option value="3">3 biurka</option>
                            <option value="4">4 biurka</option>
                            <option value="5">5 biurek</option>
                            <option value="6">6 biurek</option>
                          </select>
                        </LHInputWrap>
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <label>Status</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={booking.status}
                              onChange={e => this.setState({booking: {...booking, status: e.target.value}})}>
                        <option value="PENDING">Oczekująca</option>
                        <option value="ACTIVE">Aktywna</option>
                        <option value="REMOVED">Usunięta</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <LHButton className="btn btn-outline-purple" onClick={() => this.submitForm()}>
                    Zapisz
                  </LHButton>
                </MDBCardBody>
              </MDBCard>

            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}

DeskBookingPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

