import { combineReducers } from "redux";
import { UserReducer } from "../lib/Auth/User.reducer";
import {CalendarReducer} from "../lib/Calendar/Calendar.reducer";
import {UsersReducer} from "../lib/User/Users.reducer";
import {NotificationReducer} from "../lib/Notification/Notification.reducer";
import {CardsReducer} from "../lib/Card/Cards.reducer";
import {RoomReducer} from "../lib/Room/Room.reducer";
import {DeskReducer} from "../lib/Desk/Desk.reducer";
import {EntrancesReducer} from "../lib/Entrances/Entrances.reducer";

export default combineReducers({
  user: UserReducer,
  users: UsersReducer,
  rooms: RoomReducer,
  desks: DeskReducer,
  cards: CardsReducer,
  calendar: CalendarReducer,
  notification: NotificationReducer,
  entrances: EntrancesReducer,
});
