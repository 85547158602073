import {connect} from "react-redux";
import {RoomPage} from "./Room.page";
import {showNotification} from "../Notification/Notification.actions";
import {getRoom, saveRoom} from "./Room.actions";

const mapStateToProps = (state) => ({
  roomData: state.rooms.current,
  notification: state.notification.message,
});

const mapDispatchToProps = dispatch => {
  return {
    getRoom: (id) => dispatch(getRoom(id)),
    saveRoom: (id, data) => dispatch(saveRoom(id, data)),
    showNotification: (text) => dispatch(showNotification(text)),
  };
}

export const RoomContainer = connect(mapStateToProps, mapDispatchToProps)(RoomPage);
