import {connect} from "react-redux";
import {getUser, saveUser, getCards, getCardsHistory, getEntrancesHistory} from "./Users.actions";
import {UserPage} from "./User.page";
import {showNotification} from "../Notification/Notification.actions";

const mapStateToProps = (state) => ({
  user: state.users.current.user,
  cards: state.users.current.cards,
  notification: state.notification.message,
});

const mapDispatchToProps = dispatch => {
  return {
    getUser: (id) => dispatch(getUser(id)),
    getCards: (id) => dispatch(getCards(id)),
    getCardsHistory: (id) => dispatch(getCardsHistory(id)),
    getEntrancesHistory: (id) => dispatch(getEntrancesHistory(id)),
    saveUser: (id, payload) => dispatch(saveUser(id, payload)),
    showNotification: (text) => dispatch(showNotification(text)),
  };
}

export const UserContainer = connect(mapStateToProps, mapDispatchToProps)(UserPage);
