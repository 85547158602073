import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow
} from "mdbreact";
import NotificationComponent from "../Notification/Notification.component";
import moment from "moment";
import Autosuggest from 'react-autosuggest';
import { Redirect } from 'react-router'
import {LHAutosuggestOption, LHButton, LHInputIcon, LHInputWrap} from "../../theme/globalStyles";
import FormDatePicker from "../Common/FormDatePicker";

export class RoomBookingPage extends Component {

  constructor() {
    super();
    this.state = {
      id: null,
      bookingStartDate: '',
      booking: {
        itemId: 1,
        itemType: 'ROOM',
        bookingStart: moment().add(1, 'hour').format('YYYY-MM-DD HH:00'),
        bookingLength: 1,
        status: '',
      },
      userSearchQuery: '',
      redirectToList: false,
    };

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
  }

  getDuration(start, stop) {
    const duration = moment.duration(moment(stop).diff(start));
    return duration.asHours();
  }

  async componentDidMount() {
    const {getBooking} = this.props;
    const {id} = this.props.match.params;
    if (id === 'new') return;

    const booking = await getBooking(id);
    this.setState({
      id,
      booking: {
        ...booking,
        bookingStart: moment(booking.bookingStart).format('YYYY-MM-DD HH:mm'),
        bookingLength: this.getDuration(booking.bookingStart, booking.bookingEnd),
      },
      userSearchQuery: `${booking.user.email} - ${booking.user.firstName} ${booking.user.lastName}`,
      bookingStartDate: moment(booking.bookingStart).toDate(),
    });
  }

  async submitForm() {
    const {id, booking} = this.state;
    const {saveBooking, showNotification} = this.props;
    try {
      await saveBooking(id, booking);
      showNotification('Rezerwacja została zapisana!');
      this.setState({redirectToList: true});
    } catch (e) {
      showNotification(`Błąd: ${e.message}`);
    }
  }

  async onSuggestionsFetchRequested(event) {
    const {getUserSuggestions} = this.props;
    if (event.value.length < 2) return;
    getUserSuggestions(event.value);
  }

  updateBookingStart(date) {
    const bookingStart = moment(date).format('YYYY-MM-DD HH:mm');
    const bookingStartDate = moment(date).toDate();
    this.setState({
      bookingStartDate: bookingStartDate,
      booking: {...this.state.booking, bookingStart: bookingStart},
    });
  }
  render() {
    const {booking, userSearchQuery, redirectToList} = this.state;
    const {notification, userSuggestions} = this.props;

    if(redirectToList) return <Redirect to='/rooms'/>;

    const renderSuggestion = suggestion => (
      <LHAutosuggestOption>
        {(suggestion.firstName || suggestion.lastName) &&
        <span>{suggestion.firstName} {suggestion.lastName}</span>
        }
        <div>{suggestion.email}</div>
      </LHAutosuggestOption>
    );

    const inputProps = {
      placeholder: 'Szukaj użytkownika',
      value: userSearchQuery,
      onChange: (event, {newValue}) => {
        this.setState({userSearchQuery: newValue});
      }
    };

    const getSuggestionValue = (suggestion) => {
      this.setState({booking: {...booking, userId: suggestion.id}});
      return `${suggestion.email} - ${suggestion.firstName} ${suggestion.lastName}`;
    };

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title={
            booking.id ? 'Rezerwacja #' + booking.id : 'Nowa rezerwacja'
          }
          subtitle={
            booking.id ? 'Edycja rezerwacji' : 'Dodaj nową rezerwację'
          }
          icon="building"
        />

        <MDBContainer fluid style={{background: '#FFF'}}>
          <MDBRow>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <div className="form-group">
                    <label>Użytkownik</label>
                    <LHInputWrap icon>
                      <LHInputIcon search/>
                      <Autosuggest
                        suggestions={userSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={() => {
                        }}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        className="form-control"
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Obiekt</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={booking.itemId}
                              onChange={e => this.setState({booking: {...booking, itemId: e.target.value}})}>
                        <option value="1">Sala wideokonferencyjna - mała</option>
                        <option value="2">Sala wideokonferencyjna - duża</option>
                        <option value="3">Sala szkoleniowa</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Start rezerwacji</label>
                    <FormDatePicker
                      showTimeSelect
                      selected={this.state.bookingStartDate}
                      onChange={date => this.updateBookingStart(date)}
                      placeholder="Wybierz datę i godzinę"
                    />
                  </div>
                  <div className="form-group">
                    <label>Długość rezerwacji</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={booking.bookingLength}
                              onChange={e => this.setState({booking: {...booking, bookingLength: e.target.value}})}>
                        <option value="1">1 godzina</option>
                        <option value="2">2 godziny</option>
                        <option value="3">3 godziny</option>
                        <option value="4">4 godziny</option>
                        <option value="5">5 godzin</option>
                        <option value="6">6 godzin</option>
                        <option value="7">7 godzin</option>
                        <option value="8">8 godzin</option>
                        <option value="9">9 godzin</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Status</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={booking.status}
                              onChange={e => this.setState({booking: {...booking, status: e.target.value}})}>
                        <option value="PENDING">Oczekująca</option>
                        <option value="ACTIVE">Aktywna</option>
                        <option value="REMOVED">Usunięta</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <LHButton className="btn btn-outline-purple" onClick={() => this.submitForm()}>
                    Zapisz
                  </LHButton>
                </MDBCardBody>
              </MDBCard>

            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}

RoomBookingPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

