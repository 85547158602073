import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow
} from "mdbreact";
import NotificationComponent from "../Notification/Notification.component";
import { Redirect } from 'react-router'
import {LHButton, LHInputWrap} from "../../theme/globalStyles";

export class RoomPage extends Component {

  constructor() {
    super();
    this.state = {
      id: null,
      room: {},
      redirectToList: false,
    };
  }

  async componentDidMount() {
    const {getRoom} = this.props;
    const {id} = this.props.match.params;
    if (id === 'new') return;

    const room = await getRoom(id);
    this.setState({
      id,
      room
    });
  }

  async submitForm() {
    const {id, room} = this.state;
    const {saveRoom, showNotification} = this.props;
    try {
      await saveRoom(id, room);
      showNotification('Zmiany zostały zapisane!');
      this.setState({redirectToList: true});
    } catch (e) {
      showNotification(`Błąd: ${e.message}`);
    }
  }

  render() {
    const {room, redirectToList} = this.state;
    const {notification} = this.props;

    if(redirectToList) return <Redirect to='/rooms'/>;

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title={
            room.id ? `Sala ${room.name}` : `Nowa sala`
          }
          subtitle={
            room.id ? 'Edycja sali' : 'Dodaj nową salę'
          }
          icon="building"
        />

        <MDBContainer fluid style={{background: '#FFF'}}>
          <MDBRow>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <div className="form-group">
                    <label>Nazwa sali</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={room.name}
                        onChange={e => this.setState({room: {...room, name: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Opis</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={room.description}
                        onChange={e => this.setState({room: {...room, description: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Cena</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={room.price}
                        onChange={e => this.setState({room: {...room, price: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Free hours ratio</label>
                    <LHInputWrap>
                      <input
                        type="text"
                        className="form-control"
                        value={room.hoursRatio}
                        onChange={e => this.setState({room: {...room, hoursRatio: e.target.value}})}
                      />
                    </LHInputWrap>
                  </div>
                  <div className="form-group">
                    <label>Status</label>
                    <LHInputWrap>
                      <select className="browser-default custom-select" value={room.status}
                              onChange={e => this.setState({room: {...room, status: e.target.value}})}>
                        <option value="ACTIVE">Aktywna</option>
                        <option value="BLOCKED">Zablokowana</option>
                        <option value="REMOVED">Usunięta</option>
                      </select>
                    </LHInputWrap>
                  </div>
                  <LHButton className="btn btn-outline-purple" onClick={() => this.submitForm()}>
                    Zapisz
                  </LHButton>
                </MDBCardBody>
              </MDBCard>

            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    )
  }
}

RoomPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

