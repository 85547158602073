import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import { MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import moment from "moment";
import {LHTableStatus, LHTableWrap} from "../../theme/globalStyles";

export class CardsPage extends Component {

  componentDidMount() {
    const {getCards} = this.props;
    getCards();
  }

  render() {
    const { cards } = this.props;

    return (
      <>
        <BreadcrumSection
          title="Karty dostępu"
          subtitle="Lista wszystkich kart"
          icon="id-card"
        />

        <LHTableWrap>
          <MDBTable>
            <MDBTableHead>
              <tr>
                <th className="id-column-header">#</th>
                <th>UID</th>
                <th>Status</th>
                <th>Właściciel</th>
                <th>Ostatnia aktywność</th>
                <th>Ilość użyć</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {cards.map(card =>
                <tr key={`card-id-${card.id}`}>
                  <td className="id-column-value">{card.id}</td>
                  <td><strong>{card.uid}</strong></td>
                  <td>
                    <LHTableStatus status={card.status}>{card.status}</LHTableStatus>
                  </td>
                  <td>{card.owner && card.owner.email}</td>
                  <td>{card.lastActivity ? moment(card.lastActivity).format('DD.MM.YYYY HH:mm') : null}</td>
                  <td>{card.usages}</td>
                </tr>
              )}
            </MDBTableBody>
          </MDBTable>
        </LHTableWrap>
      </>
    )
  }
}

CardsPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

