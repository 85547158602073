import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {
  MDBIcon,
  MDBLink,
  MDBBtn,
  MDBListGroup,
  MDBListGroupItem,
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBNav,MDBNavItem, MDBNavLink, MDBTabPabe,
  MDBTabContent, MDBTabPane,
  MDBTableHead
} from "mdbreact";
import moment from "moment";
import {LHButtonOutline, LHTableActions, LHTableStatus, LHTableWrap} from "../../theme/globalStyles";
import NotificationComponent from "../Notification/Notification.component";

export class UsersPage extends Component {
  state = {
    activeItem: "1"
  };

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  componentDidMount() {
    const {getUsers, getDeletedUsers} = this.props;
    getUsers();
    getDeletedUsers();
  }

  removeUser(id) {
    if (!window.confirm('Czy na pewno chcesz usunąć tego użytkownika?')) {
      return false;
    }
    const {removeUser} = this.props;
    removeUser(id);
  }

  async reviveUser(id) {
    if (!window.confirm('Czy na pewno chcesz przywrócić tego użytkownika?')) {
      return false;
    }
    const {reviveUser, getUsers, getDeletedUsers} = this.props;
    await reviveUser(id);
    getUsers();
    getDeletedUsers();
  }

  async removeUserCard(userId, cardId) {
    const {removeUserCard, getDeletedUsers, getCards} = this.props;
    if (window.confirm('Czy na pewno chcesz usunąć przypisanie tej karty?')) {
      await removeUserCard(userId, cardId)
      setTimeout(() => {
        getDeletedUsers();
      }, 500)
    }
  }

  render() {
    const {users, deletedUsers, notification} = this.props;

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title="Użytkownicy"
          subtitle="Lista wszystkich użytkowników"
          icon="user"
        >
          <MDBLink to={`/users/new`} className="d-inline-block p-0">
            <LHButtonOutline>
              <MDBIcon icon="plus" />
              Dodaj
            </LHButtonOutline>
          </MDBLink>
        </BreadcrumSection>

        <div>
          <MDBNav className="nav-tabs">
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
                Aktywni
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
                Usunięci
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={this.state.activeItem} >
            <MDBTabPane tabId="1" role="tabpanel">
              <LHTableWrap>
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th className="id-column-header">#</th>
                      <th>Użytkownik</th>
                      <th>Rola</th>
                      <th>Data rejestracji</th>
                      <th>Aktywność kart</th>
                      <th>Status</th>
                      <th className="text-right">Akcje</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {users.map(user =>
                      <tr key={`user-id-${user.id}`}>
                        <td className="id-column-value">{user.id}</td>
                        <td>
                          <MDBLink to={`/users/${user.id}`} className="d-inline-block p-0">
                            <div>{user.firstName} {user.lastName}</div>
                            <strong>{user.email}</strong>
                          </MDBLink>
                        </td>
                        <td>
                          <LHTableStatus status={user.isAdmin ? 'ADMIN' : 'USER'}>{user.isAdmin ? 'ADMIN' : 'USER'}</LHTableStatus>
                        </td>
                        <td>{moment(user.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                        <td>
                          <MDBListGroup style={{width: "22rem"}}>
                            {user.cards.map(card => {
                              return (
                                <MDBListGroupItem key={`user-key-${card.uid}`}>
                                  {card.uid} - {card.lastActivity ? moment(card.lastActivity).format('DD.MM.YYYY HH:mm') : 'Nigdy'}
                                </MDBListGroupItem>
                              )
                            })}
                          </MDBListGroup>
                        </td>
                        <td>
                          <LHTableStatus status={user.status}>{user.status}</LHTableStatus>
                        </td>
                        <td>
                          <LHTableActions>
                            <MDBLink to={`/users/${user.id}`}>
                              <MDBIcon far icon="edit" />
                            </MDBLink>
                            <div onClick={() => this.removeUser(user.id)}>
                              <MDBIcon far icon="trash-alt" />
                            </div>
                          </LHTableActions>
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
              </LHTableWrap>
            </MDBTabPane>

            <MDBTabPane tabId="2" role="tabpanel">
              <LHTableWrap>
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th className="id-column-header">#</th>
                      <th>E-mail</th>
                      <th>Użytkownik</th>
                      <th>Rola</th>
                      <th>Karty</th>
                      <th>Status</th>
                      <th className="text-right">Akcje</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {deletedUsers.map(user =>
                      <tr key={`user-id-${user.id}`}>
                        <td className="id-column-value">{user.id}</td>
                        <td>
                          <MDBLink to={`/users/${user.id}`} className="d-inline-block p-0">
                            <div>{user.firstName} {user.lastName}</div>
                            <strong>{user.email}</strong>
                          </MDBLink>
                        </td>
                        <td>
                          <LHTableStatus status={user.isAdmin ? 'ADMIN' : 'USER'}>{user.isAdmin ? 'ADMIN' : 'USER'}</LHTableStatus>
                        </td>
                        <td>
                          <MDBListGroup>
                            {user.cards.map(card => {
                              return (
                                <div key={`user-key-${card.uid}`}
                                     style={
                                       {
                                         "display": "flex",
                                         'justify-content': 'space-between',
                                         'align-items': 'center',
                                         'border': '1px solid rgba(0,0,0,0.2)',
                                         'padding': '0 6px',
                                       }
                                     }>
                                  <div>{card.uid}</div>
                                  <MDBBtn  outline color="info" size="sm" onClick={() => this.removeUserCard(user.id, card.uid)}>Odpisz</MDBBtn>
                                </div>
                              )
                            })}
                          </MDBListGroup>
                        </td>
                        <td>
                          <LHTableStatus status={user.status}>{user.status}</LHTableStatus>
                        </td>
                        <td>
                          <LHTableActions>
                              <MDBBtn  outline size="sm" onClick={() => this.reviveUser(user.id)}>Przywróć</MDBBtn>
                          </LHTableActions>
                        </td>
                      </tr>
                    )}
                  </MDBTableBody>
                </MDBTable>
              </LHTableWrap>
            </MDBTabPane>
          </MDBTabContent>
        </div>
      </>
    )
  }
}

UsersPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

