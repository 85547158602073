import React, { Component } from "react";
import styled, {css} from "styled-components";
import logoutIcon from "../../assets/icons/logout.svg";

const StyledLogoutWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0,0,0,0.1);
  padding: 20px 0 0 20px;
  margin: 0 -20px;
  ${props => props.collapsed && css`
    ${StyledUserEmail} {
      opacity: 0;
    }
    ${StyledLogoutButton} {
      transform: translateX(20px);
    } 
  `}
`
const StyledLogoutButton = styled.div`
  display: inline-block;
  cursor: pointer;
  background-image: url(${logoutIcon});
  height:  28px;
  width: 28px;
  background-size: contain;
  position: relative;
  top: 4px;
  margin-right: 14px !important;
  opacity: .65;
  transform: translateX(0);
  transition: opacity .2s, transform .2s;
  &:hover {
    opacity: 1;
  }
`
const StyledUserEmail = styled.span`
  opacity: 1;
  transition: opacity .2s;
  span {
    font-size: .9em;
    opacity: .7;
  }
  div{
    width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
class Logout extends Component {

    logout = () => {
        localStorage.clear("accessToken");
        window.location.href = "/"
    };

    render() {
        return (
          <StyledLogoutWrap collapsed={ this.props.collapsed }>
            <div>
              <StyledLogoutButton onClick={ this.logout } icon="long-arrow-alt-left" className="mr-3"/>
            </div>
            <StyledUserEmail>
              <span>Zalogowany jako</span>
              <div>{ this.props.user }</div>
            </StyledUserEmail>
          </StyledLogoutWrap>
        )
    }
}

export default Logout;
