import {connect} from "react-redux";
import {getBookings, removeBooking} from "./Desk.actions";
import {DesksPage} from "./Desks.page";
import {showNotification} from "../Notification/Notification.actions";

const mapStateToProps = (state) => ({
  bookings: state.rooms.bookings,
  notification: state.notification.message,
});

const mapDispatchToProps = dispatch => {
  return {
    getBookings: (id) => dispatch(getBookings(id)),
    removeBooking: (id) => dispatch(removeBooking(id)),
    showNotification: (text) => dispatch(showNotification(text)),
  };
}

export const DesksContainer = connect(mapStateToProps, mapDispatchToProps)(DesksPage);
