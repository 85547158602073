import {CALENDAR_ACTIONS} from "./Calendar.actions";

export const CalendarReducer = (state = {
  personalBookings: [],
}, action) => {
  switch (action.type) {
    case CALENDAR_ACTIONS.UPDATE_PERSONAL_BOOKINGS:
      return {...state, personalBookings: action.payload};
    default:
      return state
  }
};
