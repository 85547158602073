export const NOTIFICATION_ACTIONS = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
};

export const showNotification = (message) => async (dispatch) => {
  dispatch({type: NOTIFICATION_ACTIONS.SHOW_NOTIFICATION, payload: message});
  setTimeout(() => {
    dispatch({type: NOTIFICATION_ACTIONS.HIDE_NOTIFICATION});
  }, 3500);
};
