import {connect} from "react-redux";
import {UsersPage} from "./Users.page";
import {getUsers, getDeletedUsers, removeUser, reviveUser, removeUserCard} from "./Users.actions";
import {showNotification} from "../Notification/Notification.actions";

const mapStateToProps = (state) => ({
  users: state.users.list,
  deletedUsers: state.users.deleted,
  notification: state.notification.message,
});

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(getUsers()),
    getDeletedUsers: () => dispatch(getDeletedUsers()),
    removeUser: (id) => dispatch(removeUser(id)),
    removeUserCard: (id, payload) => dispatch(removeUserCard(id, payload)),
    reviveUser: (id) => dispatch(reviveUser(id)),
    showNotification: (text) => dispatch(showNotification(text)),
  }
}

export const UsersContainer = connect(mapStateToProps, mapDispatchToProps)(UsersPage);
