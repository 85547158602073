import {ROOM_ACTIONS} from "./Room.actions";

export const RoomReducer = (state = {
  list: [],
  current: null,
  bookings: [],
  booking: null,
  userSuggestions: [],
}, action) => {
  switch (action.type) {
    case ROOM_ACTIONS.GET_ROOMS:
      return {
        ...state,
        list: action.payload
      };
    case ROOM_ACTIONS.GET_ROOM:
      return {
        ...state,
        current: action.payload
      };
    case ROOM_ACTIONS.GET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      };
    case ROOM_ACTIONS.GET_BOOKING:
      return {
        ...state,
        booking: action.payload
      };
    case ROOM_ACTIONS.REMOVE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.filter(booking => booking.id !== action.payload.id)
      };
    case ROOM_ACTIONS.GET_USER_SUGGESTIONS:
      return {
        ...state,
        userSuggestions: action.payload
      };
    default:
      return state;
  }
};
