import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import NotificationComponent from "../Notification/Notification.component";
import {BookingsTableComponent} from "./components/BookingsTable.component";
import styled from 'styled-components';
import {LHButtonOutline} from "../../theme/globalStyles";
import {MDBIcon, MDBLink} from "mdbreact";

const StyledTableCard = styled.div`
  margin-bottom: 50px;
  h5 {
    padding: 10px 0;
    font-weight: 600;
  }
`

export class DesksPage extends Component {

  constructor() {
    super();
    this.state = {
      dedicatedBookings: [],
      dailyBookings: [],
    };

    this.onBookingRemove = this.onBookingRemove.bind(this);
  }

  async componentDidMount() {
    this.updateList();
  }

  async onBookingRemove(id){
    if(!window.confirm('Czy na pewno chcesz usunąć tą rezerwację?')) return false;
    const {removeBooking} = this.props;
    await removeBooking(id);
    this.updateList();
  }

  async updateList(){
    const {getBookings} = this.props;
    const bookings = await getBookings();

    const dedicatedBookings = bookings.filter(booking => booking.itemId);
    const dailyBookings = bookings.filter(booking => !booking.itemId);

    this.setState({dedicatedBookings, dailyBookings});
  }

  render() {
    const {dedicatedBookings, dailyBookings} = this.state;
    const {notification} = this.props;

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title="Rezerwacje biurek"
          subtitle="Lista wszystkich zarezerwowanych biurek"
          icon="square"
        >
          <MDBLink to={`/desks/booking/new`} className="d-inline-block p-0">
            <LHButtonOutline>
              <MDBIcon icon="plus" />
              Dodaj rezerwację
            </LHButtonOutline>
          </MDBLink>
        </BreadcrumSection>

        <StyledTableCard>
          <h5>
            Biurka dedykowane
          </h5>
          <BookingsTableComponent bookings={dedicatedBookings} onBookingRemove={this.onBookingRemove}/>
        </StyledTableCard>

        <StyledTableCard>
          <h5>Hot-Desk</h5>
          <BookingsTableComponent bookings={dailyBookings} onBookingRemove={this.onBookingRemove}/>
        </StyledTableCard>
      </>
    )
  }
}

DesksPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

