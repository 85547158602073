import {connect} from "react-redux";
import {DeskBookingPage} from "./DeskBooking.page";
import {showNotification} from "../Notification/Notification.actions";
import {getBooking, saveBooking} from "./Desk.actions";
import {getUserSuggestions} from "../Room/Room.actions";

const mapStateToProps = (state) => ({
  booking: state.desks.booking,
  notification: state.notification.message,
  userSuggestions: state.rooms.userSuggestions,
});

const mapDispatchToProps = dispatch => {
  return {
    getBooking: (id) => dispatch(getBooking(id)),
    saveBooking: (id, data) => dispatch(saveBooking(id, data)),
    showNotification: (text) => dispatch(showNotification(text)),
    getUserSuggestions: (text) => dispatch(getUserSuggestions(text)),
  };
}

export const DeskBookingContainer = connect(mapStateToProps, mapDispatchToProps)(DeskBookingPage);
