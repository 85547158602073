import {connect} from "react-redux";
import {CalendarPage} from "./Calendar.page";
import {getRooms} from '../Room/Room.actions';
import {getAllBookings} from "./Calendar.actions";

const mapStateToProps = (state) => ({
  personalBookings: state.calendar.personalBookings,
  rooms: state.rooms.list,
});

const mapDispatchToProps = dispatch => {
  return {
    getAllBookings: (start, end) => dispatch(getAllBookings(start,end)),
    getRooms: (id) => dispatch(getRooms(id)),
  }
};

export const CalendarContainer = connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
