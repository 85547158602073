import React, {Component} from 'react';
import BreadcrumSection from "../Common/BreadcrumSection";
import {MDBIcon, MDBLink, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import NotificationComponent from "../Notification/Notification.component";
import {LHTableActions, LHTableStatus, LHTableWrap} from "../../theme/globalStyles";

export class RoomsListPage extends Component {

  constructor() {
    super();
    this.state = {};
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const {getRooms} = this.props;
    await getRooms(id);
  }

  render() {
    const {rooms, notification} = this.props;

    return (
      <>
        {notification && <NotificationComponent message={notification}/>}
        <BreadcrumSection
          title="Sale"
          subtitle="Lista wszystkich sal"
          icon="building"
        >
        </BreadcrumSection>

        <LHTableWrap>
          <MDBTable>
            <MDBTableHead>
              <tr>
                <th>Nazwa</th>
                <th>Cena</th>
                <th>Free Hours ratio</th>
                <th>Status</th>
                <th className="text-right">Akcja</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {rooms.map(room =>
                <tr key={`room-id-${room.id}`}>
                  <td><MDBLink to={`/rooms/${room.id}/bookings`}>{room.name}</MDBLink></td>
                  <td>{room.price}</td>
                  <td>{room.hoursRatio}</td>
                  <td><LHTableStatus status={room.status}>{room.status}</LHTableStatus></td>
                  <td>
                    <LHTableActions>
                      <MDBLink to={`/rooms/${room.id}`} className="d-inline-block p-0">
                        <MDBIcon far icon="edit"/>
                      </MDBLink>
                    </LHTableActions>
                  </td>
                </tr>
              )}
            </MDBTableBody>
          </MDBTable>
        </LHTableWrap>
      </>
    )
  }
}

RoomsListPage.propTypes = {
  //updateSelectedPackage: PropTypes.func,
  //isDailyDeskBookingModalVisible: PropTypes.bool,
  //selectedPackage: PropTypes.string,
};

