import React from 'react';
import {MDBCard, MDBRow, MDBCol } from 'mdbreact';
import {LHButton, LHButtonOutline} from "../../theme/globalStyles";
import coworkingIcon from "../../assets/icons/coworking.svg"
import conferenceIcon from "../../assets/icons/conference.svg"
import officeIcon from "../../assets/icons/office.svg"
import styled from "styled-components"

const StyledCardsIcon = styled.div`
  height: 100px;
  width: 100px;
  display: inline-block;
  position: relative;
  top: 30px;
  left: 10px;
  background-image: url(${props => props.icon });
  background-size: contain;
`

const CardsSection = () => {
  return (
    <MDBRow className="mb-4">
      <MDBCol xl="4" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <StyledCardsIcon icon={ coworkingIcon } />
            <div className="data">
              <p>MOJE BIURKO</p>
              <p>Posiadasz rezerwację <strong>1</strong> biurka na łącznie <strong>2 dni</strong></p>
              <p><LHButton size="sm">Przedłuż</LHButton></p>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="4" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <StyledCardsIcon icon={ conferenceIcon } />
            <div className="data">
              <p>MOJE SALE</p>
              <p>Możesz zarezerwować sale na <strong>6 godzin</strong></p>
              <p>
                <LHButtonOutline size="sm">Dokup czas</LHButtonOutline>
                <LHButton size="sm">Rezerwuj</LHButton>
              </p>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="4" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <StyledCardsIcon icon={ officeIcon }/>
            <div className="data">
              <p>MOJE WIRTUALNE BIURO</p>
              <p>Twój pakiet jest aktywy do <strong>30 marca 2020</strong></p>
              <p><LHButton  size="sm">Przedłuż</LHButton></p>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  )
}

export default CardsSection;

