import {DESK_ACTIONS} from "./Desk.actions";

export const DeskReducer = (state = {
  bookings: [],
  booking: null,
}, action) => {
  switch (action.type) {
    case DESK_ACTIONS.GET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      };
    case DESK_ACTIONS.GET_BOOKING:
      return {
        ...state,
        booking: action.payload
      };
    case DESK_ACTIONS.REMOVE_BOOKING:
      return {
        ...state,
        bookings: state.bookings.filter(booking => booking.id !== action.payload.id)
      };
    case DESK_ACTIONS.GET_USER_SUGGESTIONS:
      return {
        ...state,
        userSuggestions: action.payload
      };
    default:
      return state;
  }
};
