import {NOTIFICATION_ACTIONS} from "./Notification.actions";

export const NotificationReducer = (state = {
  message: null,
}, action) => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.SHOW_NOTIFICATION:
      return {
        ...state,
        message: action.payload,
      };
    case NOTIFICATION_ACTIONS.HIDE_NOTIFICATION:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};
