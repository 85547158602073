import React from 'react';
import {
    MDBCol,
    MDBContainer,
    MDBRow
} from "mdbreact";
import styled from "styled-components";
import loginBg from "../../assets/bg-login.jpg";
import logo from "../../assets/250px-LH-logo-light-bg.png";


const StyledLoginPanel = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`
const StyledLoginFormWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 2;
  background-color: ${({ theme }) => theme.background};
  .container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .col {
      width: 480px;
    }
  }
`
const StyledLoginFormLogo = styled.div`
  text-align: center;
  margin-bottom: 50px;
  img {
    width: 140px;
  }
  
`
const StyledLoginImage = styled.div`
  flex: 3;
  background-color: ${({ theme }) => theme.background};
  padding: 20px;
  &:after {
      content: '';
      display: block;
      height: 100%;
      background-image: radial-gradient(transparent, rgb(30, 27, 58)), linear-gradient(to right, rgba(33, 27, 86, 0.6), transparent), url(${loginBg});
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
}
}
`
const StyledLoginPanelSocials = styled.div`
    ul {
          display: flex;
          padding: 0;
          margin-bottom: 30px;
        li {
          list-style: none;
          padding: 0 20px 0 0;
          a {
            opacity: .5;
          &:hover {
            color: var(--main);
            opacity: 1;
          }
          }
        }
    }
`

const LoginPanel = ({ children }) => (
    <StyledLoginPanel>
        <StyledLoginFormWrap>
            <MDBContainer>
                <MDBRow center>
                    <MDBCol>
                        <StyledLoginFormLogo>
                            <img alt="app logo" src={logo}/>
                        </StyledLoginFormLogo>
                        { children }
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
                <StyledLoginPanelSocials>
                    <ul>
                        <li><a href="https://www.facebook.com/lesnahub/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://www.instagram.com/lesnahub/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/le%C5%9Bna-hub/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                    </ul>
                </StyledLoginPanelSocials>
        </StyledLoginFormWrap>
        <StyledLoginImage />
    </StyledLoginPanel>
)

export default LoginPanel;
