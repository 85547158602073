import {connect} from "react-redux";
import {EntrancesPage} from "./Entrances.page";
import { getAllEntrancesHistory } from "./Entrances.actions";
import { getUsers } from "../User/Users.actions";

const mapStateToProps = (state) => ({
  entrances: state.entrances.list,
  users: state.users.list,
});

const mapDispatchToProps = dispatch => {
  return {
    getAllEntrancesHistory: () => dispatch(getAllEntrancesHistory()),
    getUsers: () => dispatch(getUsers()),
  }
}

export const EntrancesContainer = connect(mapStateToProps, mapDispatchToProps)(EntrancesPage);
