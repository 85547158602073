import React, {Component} from 'react';
import CardsSection from '../Common/CardsSection';
import {
  Scheduler,
  MonthView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  TodayButton,
  Resources,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import BreadcrumSection from "../Common/BreadcrumSection";
import {ViewState} from "@devexpress/dx-react-scheduler";
import moment from "moment";
import * as qs from 'query-string';
import {MDBAlert, MDBIcon} from "mdbreact";
import * as Styled from "./Calendar.style";


export class CalendarPage extends Component {

  async componentDidMount() {
    const {getAllBookings, getRooms} = this.props;
    const date = new Date();
    const bookingStart = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
    await getRooms();
    getAllBookings(bookingStart, moment(bookingStart).endOf('isoWeek').format('YYYY-MM-DD'));
  }

  updateCurrentView(date){
    const {getAllBookings} = this.props;
     const bookingStart = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
     getAllBookings(bookingStart, moment(bookingStart).endOf('isoWeek').format('YYYY-MM-DD'));
  }
  
  formatWord(itemType, quantity) {
    if (itemType === 'DESK') {
      if (quantity === 1) return 'biurko';
      let lastDigit;
      if (String(quantity).length > 1) {
        lastDigit = parseInt(String(quantity)[String(quantity).length - 1]);
      } else {
        lastDigit = quantity;
      }
      if (lastDigit === 1 || lastDigit > 4) return 'biurek';
      return 'biurka';
    }
  }

  formatBookingTitle(booking) {
    const {rooms} = this.props;
    if (booking.itemType === 'ROOM') {
      return `${rooms ? rooms.find(room => room.id === booking.itemId).name : ''}: 
      ${moment(booking.bookingStart).format('HH:mm')} - ${moment(booking.bookingEnd).format('HH:mm')}`;
    } else {
      if (booking.itemId) {
        return `Biurko nr ${booking.itemId}: 
        ${moment(booking.bookingStart).format('DD.MM.YYYY')} - ${moment(booking.bookingEnd).format('DD.MM.YYYY')}`;
      } else {
        return `Hot-desk: ${booking.quantity} ${this.formatWord('DESK', booking.quantity)} 
        ${moment(booking.bookingStart).format('DD.MM.YYYY')} - ${moment(booking.bookingEnd).format('DD.MM.YYYY')}`;
      }
    }
  }

  appointmentNotes({children, appointmentData, ...restProps}) {
    return (
      <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>

        <Styled.AppointmentNotes>
          <Styled.AppointmentNotesLabel><MDBIcon far icon="user" className="mr-3"/></Styled.AppointmentNotesLabel>
          <Styled.AppointmentMessage>{appointmentData.user}</Styled.AppointmentMessage>
        </Styled.AppointmentNotes>
        {appointmentData.notes &&
        <Styled.AppointmentNotes>
          <Styled.AppointmentNotesLabel>Uwagi:</Styled.AppointmentNotesLabel>
          <Styled.AppointmentMessage>{appointmentData.notes}</Styled.AppointmentMessage>
        </Styled.AppointmentNotes>
        }
      </AppointmentTooltip.Content>
    )
  }
  
  getColorId(data) {
    if (data.itemId === 1)
      return 1;
    else if (data.itemId == 2)
      return 2;
    else
      return 3;
  }

  formatCalendarData(data) {
    return data.map((booking) => {
      return {
        startDate: moment(booking.bookingStart).format('YYYY-MM-DD HH:mm'),
        endDate: moment(booking.bookingEnd).format('YYYY-MM-DD HH:mm'),
        title: this.formatBookingTitle(booking),
        user: booking.user,
        colorId: this.getColorId(booking),
      }
    });
  }

  dataTable() {
    const personalBookings = this.props.personalBookings;
    const hotDesks = personalBookings.filter(booking => {
      return booking.itemType === 'DESK' && booking.itemId === null
    })
    const dedicated = personalBookings.filter(booking => {
      return booking.itemType === 'DESK' && booking.itemId !== null
    })
    const room1 = personalBookings.filter(booking => {
      return booking.itemType === 'ROOM' && booking.itemId === 1
    })
    const room2 = personalBookings.filter(booking => {
      return booking.itemType === 'ROOM' && booking.itemId === 2
    })
    return {
      desks: {
        hotDesks: hotDesks,
        dedicated: dedicated,
      },
      rooms: {
        room1: room1,
        room2: room2,
      }
    }
  }



  render() {
    const {personalBookings} = this.props;
    const data = this.formatCalendarData(personalBookings);
    const queryParams = qs.parse(window.location.search);

    return (
      <>
        <BreadcrumSection 
          page="Pulpit"
          title="Kalendarz rezerwacji"
          subtitle="Rezerwacje sal konferencyjnych"
          icon="calendar"
        />

        {queryParams && queryParams.payment && (
          <MDBAlert color="success" >Dziękujemy za dokonanie płatności.</MDBAlert>
        )}

        <div style={{background: '#FFF'}}>
          <Scheduler
            locale="pl-PL"
            firstDayOfWeek={1}
            data={data}
          >
            <ViewState
              defaultCurrentDate={moment().format()}
              onCurrentDateChange={(date) => this.updateCurrentView(date)}
            />
            <WeekView
              startDayHour={7}
              endDayHour={22}
              displayName="Rezerwacja sal"
            />
            <Toolbar/>
            <DateNavigator/>
            <TodayButton/>
            <Appointments/>
            <AppointmentTooltip
              contentComponent={this.appointmentNotes}
            />
            <Resources
              data={[{
                id: 1,
                fieldName: 'colorId',
                instances: [
                  {id: 1, color: '#727bd2'},
                  {id: 2, color: '#32c9ed'},
                  {id: 3, color: '#2a7ee4'},
                ],
              }]}
            />
          </Scheduler>
        </div>
      </>
    );
  }
}
