import {connect} from "react-redux";
import {CardsPage} from "./Cards.page";
import {getCards} from "./Cards.actions";

const mapStateToProps = (state) => ({
  cards: state.cards.list,
});

const mapDispatchToProps = dispatch => {
  return {
    getCards: () => dispatch(getCards()),
  }
}

export const CardsContainer = connect(mapStateToProps, mapDispatchToProps)(CardsPage);
