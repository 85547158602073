import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const CARDS_ACTIONS = {
  SET_CARDS: 'SET_CARDS',
};

export const getCards = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/auth/card`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });
    const {data} = response;
    dispatch({type: CARDS_ACTIONS.SET_CARDS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
