import axios from "axios";
import {backendHost} from "../../common/helpers/http";
import {requestErrorHandler} from "../../common/helpers/errorHandler";

export const CALENDAR_ACTIONS = {
  UPDATE_PERSONAL_BOOKINGS: 'UPDATE_PERSONAL_BOOKINGS',
};

export const getAllBookings = (bookingStart, bookingEnd) => async (dispatch, getState) => {
  try {
    const state = getState();
    const {accessToken} = state.user;
    const response = await axios.get(
      `${backendHost}/booking/all?bookingStart=${bookingStart}&bookingEnd=${bookingEnd}`,
      {
        headers: {'Authorization': `Bearer ${accessToken}`}
      });

    const {data} = response;
    dispatch({type: CALENDAR_ACTIONS.UPDATE_PERSONAL_BOOKINGS, payload: data});
    return data;
  } catch (error) {
    requestErrorHandler(error);
    return false;
  }
};
