import {USERS_ACTIONS} from "./Users.actions";

export const UsersReducer = (state = {
  list: [],
  deleted: [],
  current: {
    user: null,
    cards: [],
  },
}, action) => {
  switch (action.type) {
    case USERS_ACTIONS.SET_USERS:
      return {
        ...state,
        list: action.payload
      };
    case USERS_ACTIONS.SET_DELETED_USERS:
      return {
        ...state,
        deleted: action.payload
      };
    case USERS_ACTIONS.SET_USER:
      return {
        ...state,
        current: {...state.current, user: action.payload}
      };
    case USERS_ACTIONS.REMOVE_USER:
      return {
        ...state,
        list: state.list.filter(user => user.id !== action.payload.id)
      };
    case USERS_ACTIONS.SET_CARDS:
      return {
        ...state,
        current: {...state.current, cards: action.payload}
      };
    default:
      return state;
  }
};
